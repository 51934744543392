import { ReactComponent as AuctionIcon } from 'assets/images/blockchain-domains-icons/auction-icon.svg';
import { ReactComponent as BlockchainIconAurora } from 'assets/images/blockchain-domains-icons/aurora-icon.svg';
import { ReactComponent as BlockchainIconNear } from 'assets/images/blockchain-domains-icons/near-icon.svg';
import { ReactComponent as BlockchainIconSolana } from 'assets/images/blockchain-domains-icons/solana-icon.svg';

enum BlockchainImgEnum {
  Near,
  Solana,
  Aurora,
  Auction,
}

interface IBlockchainDomains {
  value: string,
  type: BlockchainImgEnum,
  name: string,
  isActive?: boolean
}

export const blockchainDomains: IBlockchainDomains[] = [
  {
    value: 'https://mars.bocachica.io/',
    type: BlockchainImgEnum.Near,
    name: 'NEAR',
  },
  {
    value: 'https://moon.bocachica.io/',
    type: BlockchainImgEnum.Solana,
    name: 'Solana',
  },
  {
    value: 'https://auction.bocachica.io/',
    type: BlockchainImgEnum.Auction,
    name: 'Auction',
    isActive: true,
  },
];

export const DomainImageMap:{ [key: number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  [BlockchainImgEnum.Near]: BlockchainIconNear,
  [BlockchainImgEnum.Solana]: BlockchainIconSolana,
  [BlockchainImgEnum.Aurora]: BlockchainIconAurora,
  [BlockchainImgEnum.Auction]: AuctionIcon,
};

export enum EColorType {
  LOADING,
  ACTIVE,
  EMPTY,
}
export const getColorType = (loading: boolean, isActive?: boolean) => {
  if (loading) return EColorType.LOADING;
  if (isActive) return EColorType.ACTIVE;
  return EColorType.EMPTY;
};
