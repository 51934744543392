import styled from 'styled-components';

import { ReactComponent as BocaChica } from 'assets/images/boca-logo.svg';
import { ReactComponent as WalletLogo } from 'assets/images/icons/wallet.svg';
import Buttons from 'shared/components/Buttons';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem 2.5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    align-self: center;
    justify-content: space-between;
    padding: 1.5rem 2rem 1.5rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1.25rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 20.5rem;
    padding: 1.5rem 0 1.5rem;
  `}
`;

const LeftRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-area: 1 / 1 / 2 / 2;
  `}
`;

const BocaChicaLogo = styled.div<{ isLoading?: boolean }>`
  transition: opacity .3s;
  opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-area: 1 / 2 / 2 / 3; 
  `}
`;

const CentralRow = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 36px;
    grid-area: 2 / 1 / 3 / 3;
    justify-content: center;
  `}
`;

const RightRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(Buttons.ConnectedButton)<{ isOpened: boolean }>`
  font-weight: 600;
  font-size: .75rem;
  line-height: .938rem;
  & > svg {
    margin-right: .5rem;
  };
  visibility: ${(({ isOpened }) => (!isOpened ? 'visible' : 'hidden'))};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
    grid-area: 1 / 2 / 2 / 3; 
  `}
`;

export default {
  Container,
  LeftRow,
  WalletLogo,
  BocaChicaLogo,
  BocaChica,
  CentralRow,
  RightRow,
  Button,
};
