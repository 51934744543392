import styled from 'styled-components';

import { EColorType } from 'shared/utils';

const DomainsWrapper = styled.div`
  display: flex;
`;

const BlockchainDomainButton = styled.a<{ colorType: EColorType }>`
  display: flex;
  align-items: center;
  margin-right: .75rem;
  padding: .563rem;
  background: ${({ theme, colorType }) => (colorType === EColorType.ACTIVE ? theme.white : theme.opacityGray)};
  color: ${({ theme, colorType }) => {
    if (colorType === EColorType.LOADING) return theme.transparent;
    if (colorType === EColorType.ACTIVE) return theme.black;
    return theme.gray;
  }};
  border-radius: 1rem;
  text-decoration: none;
  transition: all .3s;
  & > svg {
    width: .75rem;
    height: .75rem;
    margin-right: .5rem;
    path {
      fill: ${({ theme, colorType }) => {
    if (colorType === EColorType.LOADING) return theme.transparent;
    if (colorType === EColorType.ACTIVE) return theme.black;
    return theme.gray;
  }};
    }
  }
  :last-child {
    margin-right: 0;
  }
  :hover {
    color: ${({ theme, colorType }) => (colorType === EColorType.LOADING ? theme.transparent : theme.black)};
    background: ${({ theme }) => theme.white};
    svg {
      path {
        fill: ${({ theme, colorType }) => (colorType === EColorType.LOADING ? theme.transparent : theme.black)};
      }
    }
  }
`;

const BlockchainDomainName = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export default {
  DomainsWrapper,
  BlockchainDomainButton,
  BlockchainDomainName,
};
