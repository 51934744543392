import { blockchainDomains, DomainImageMap, getColorType } from 'shared/utils';

import styles from './styles';

interface IBlockchainDomains {
  loading: boolean;
}

export default function BlockchainDomainsImage({ loading }: IBlockchainDomains) {
  return (
    <styles.DomainsWrapper>
      {blockchainDomains.map(({
        value, type, name, isActive,
      }) => {
        const DomainImage = DomainImageMap[type];
        return (
          <styles.BlockchainDomainButton
            key={value}
            href={value}
            target="_blank"
            rel="noreferrer"
            colorType={getColorType(loading, isActive)}
          >
            <DomainImage />
            <styles.BlockchainDomainName>
              {name}
            </styles.BlockchainDomainName>
          </styles.BlockchainDomainButton>
        );
      })}
    </styles.DomainsWrapper>
  );
}
